import React from "react";
import Bg from "../../assets/unsplash_ZV79vhL6ppk.svg";
import { IoArrowForward } from "react-icons/io5";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaMapMarkerAlt,
  FaRegEnvelope,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { FiPhone } from "react-icons/fi";
import Varnion from "../../assets/Linklogo.png.svg";
import Accordion from "../accordionCloud";

function Footer() {
  return (
    <div className="bg-black z-10">
      <div className="z-30">
        <img
          src={Bg}
          alt="bg"
          className="w-full object-cover h-[380px] lg:h-[450px] xs:h-[400px] 2xl:h-[500px] z-30"
        />
        <div className="-mt-[350px] md:-mt-[310px] lg:-mt-[27%] xl:-mt-[340px] xs:-mt-[355px] 2xl:-mt-[350px] lg:px-8 px-4 absolute text-white z-40 ">
          <div className="lg:flex justify-between items-start">
            <div className="text-[32px] lg:text-6xl font-medium w-10/12 md:w-8/12 lg:w-7/12 leading-[39px] lg:leading-[70px] 2xl:w-5/12">
              <span className="block lg:inline md:inline ">Kami memahami</span>
              <span className="block md:inline lg:inline "> Pentingnya </span>
              Teknologi dalam Bisnis.{" "}
            </div>
            <div className="mt-3 text-xl lg:w-[23%] ">
              <span className="block sm:inline lg:inline md:block xs:inline">
                {" "}
                Kontak Varnion CloudOne sekarang untuk dapatkan{" "}
              </span>
              konsultasi teknologi dan layanan internet terbarukan.
            </div>
          </div>
          <div className="lg:flex">
            {/* <buton > */}
            <a
              href="https://wa.me/62816966285"
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center bg-btnFooter p-3 rounded-3xl mt-4 hover:bg-[#436de2]"
            >
              KONTAK SALES: 0816-966-285
              <IoArrowForward className="ms-2" size={18} />
            </a>
            {/* </buton> */}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap px-4 pt-10 pb-6 lg:py-6 text-xl justify-center text-white bg-black items-center lg:hidden">
        <a
          href="https://www.facebook.com/varnionsemesta"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex items-center me-3 mb-2">
            <FaFacebookF className="me-2" size={24} /> varnionsemesta
          </div>
        </a>
        <a
          href="https://www.instagram.com/varnionsemesta/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex items-center me-3 mb-2">
            <FaInstagram className="me-2" size={24} /> varnionsemesta
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UC2BgYJH43SU4uHe5ejXLXVg"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex items-center me-3 mb-2">
            <FaYoutube className="me-2" size={24} /> varnionzer
          </div>
        </a>
        <a
          href="https://twitter.com/varnionsemesta"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex items-center me-3 mb-2">
            <FaTwitter className="me-2" size={24} /> varnion
          </div>
        </a>
        <a
          href="https://www.linkedin.com/company/pt-varnion-technology-semesta/"
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex items-center me-3 mb-2">
            <FaLinkedin className="me-2" size={24} /> varnion
          </div>
        </a>
      </div>
      <div className="lg:flex justify-between lg:py-12  z-30">
        <div className="bg-black z-30 lg:w-[50%]  lg:order-2 lg:flex justify-end items-baseline">
          <div className="px-4 text-white lg:w-[60%]  z-30">
            <div className="font-semibold text-2xl">Alamat</div>
            <p className="text-xl ">Jakarta</p>
            <div className="flex items-baseline">
              <FaMapMarkerAlt className="me-2" />{" "}
              <span>
                <span className="block sm:inline xs:inline md:block">
                  Cyber Building 3rd Floor Kuningan Barat No. 8
                </span>{" "}
                South Jakarta 12710 - Indonesia
              </span>
            </div>
            <div className="flex items-center">
              <FiPhone className="me-2" /> <span>0816-966-285</span>
            </div>
            <div className="flex items-center">
              <FaRegEnvelope className="me-2" />{" "}
              <span>appskeren@varnion.com</span>
            </div>
          </div>
        </div>
        <div className=" z-30 lg:w-[50%] xs:mt-5 lg:order-1 lg:ps-2">
          <div className="px-4 text-white lg:w-[30%]">
            {/* <div className="border-t border-b">CLOUDSUITE</div> */}
            <div>
              <div className="mb-3 font-semibold text-lg">CLOUDSUITE</div>
              <div>
                <div>
                  <a
                    href="https://vlepo.id/"
                    className="text-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Vlepo
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.varnion.com/vtech/megalos"
                    className="text-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Megalos
                  </a>
                </div>
                <div>
                  <a
                    href="https://keponet.com/"
                    className="text-white"
                    target="_blank"
                    rel="noreferrer"
                  >
                    KEPONET
                  </a>
                </div>
              </div>
            </div>
            {/* <Accordion
              nameShow={"CLOUDSUITE"}
              content={
                <>
                  <div>
                    <a
                      href="https://vlepo.id/"
                      className="text-white"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Vlepo
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.varnion.com/vtech/megalos"
                      className="text-white"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Megalos
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://keponet.com/"
                      className="text-white"
                      target="_blank"
                      rel="noreferrer"
                    >
                      KEPONET
                    </a>
                  </div>
                </>
              }
            /> */}
          </div>
        </div>
      </div>
      <hr className="hidden lg:block m-0 p-0" />
      <div className="px-4 bg-black text-white py-4 lg:flex lg:items-center justify-between">
        <div className="flex justify-center mb-6 lg:w-40%  order-2">
          <img src={Varnion} alt="Varnion" />
        </div>
        <hr className=" pb-4 mx-[20%] lg:hidden" />
        {/*  */}
        <div className="text-center lg:text-start lg:w-55%  order-1 ">
          <div className="hidden lg:block">
            <div className="flex flex-wrap px-4 py-6 lg:p-0 text-xl justify-center text-white items-center ">
              <a
                href="https://www.facebook.com/varnionsemesta"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center me-3 mb-2 lg:text-[16px]">
                  <FaFacebookF className="me-2" size={18} /> varnionsemesta
                </div>
              </a>
              <a
                href="https://www.instagram.com/varnionsemesta/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center me-3 mb-2 lg:text-[16px]">
                  <FaInstagram className="me-2" size={18} /> varnionsemesta
                </div>
              </a>
              <a
                href="https://www.youtube.com/channel/UC2BgYJH43SU4uHe5ejXLXVg"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center me-3 mb-2 lg:text-[16px]">
                  <FaYoutube className="me-2" size={18} /> varnionzer
                </div>
              </a>
              <a
                href="https://twitter.com/varnionsemesta "
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center me-3 mb-2 lg:text-[16px]">
                  <FaTwitter className="me-2" size={18} /> varnion
                </div>
              </a>
              <a
                href="https://www.linkedin.com/company/pt-varnion-technology-semesta/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center me-3 mb-2 lg:text-[16px]">
                  <FaLinkedin className="me-2" size={18} /> varnion
                </div>
              </a>
            </div>
          </div>
          <div className="lg:text-[14px]">Privacy Policy Copyright ©2023</div>
          <div className="lg:text-[14px]">PT. Varnion Technology Semesta</div>
        </div>
      </div>
    </div>
  );
}
export default Footer;
