import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import "../../assets/styles/main.css";
import { FaWhatsapp } from "react-icons/fa";
import Bg from "../../assets/bg.jpg";
import Varnion from "../../assets/mstile-150x150.png";

function Layout({ children }) {
  
  return (
    <>
      <Helmet title="CloudOne">
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="../../assets/styles/main.css" rel="stylesheet" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
       <link rel="shortcut icon" href="../../assets/mstile-150x150.png" />
      </Helmet>
      <div>
        <Navbar />
        {children}
        <div className="fixed bottom-4 right-4 z-50">
          <button className="bg-btnNavbar hover:bg-btnNavbar50 shadow-custom text-white p-2 rounded-full">
          <a href="https://wa.me/62816966285" target="_blank"  >
              <FaWhatsapp className="text-[27px] md:text-[30px] lg:text-[32px]" />
          </a>
          </button>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Layout;
