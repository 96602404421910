import React from "react";
import Logo from "../../assets/Logo Utama (1).png";
import { FiPhone } from "react-icons/fi";
function Navbar() {
  return (
    <div className="sticky top-0 z-50 bg-white shadow lg:px-8 ">
      <div className="flex items-center justify-between p-2 lg:py-2">
        <div className="w-1/2 lg:w-1/4">
          <img src={Logo} alt="varnion" className="w-10/12" />
        </div>
        <div className="w-1/2 flex  justify-end text-end">
          <div className="">
            <div className="flex items-center mb-[3px]">
              <FiPhone className="me-1" /> <span>Sales: 0816-966-285</span>
            </div>
            <button className="bg-btnNavbar hover:bg-btnNavbar50 shadow-custom  px-9 mt-2 rounded-lg py-[5px] text-white text-center">
            <a
              href="https://wa.me/62816966285"
              target="_blank"
              
            >
              Kontak Kami
            </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
